import React from 'react';
import { Component } from 'react';
import { auth, refUsers, refLocations } from '../config';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { addDoc } from 'firebase/firestore';


class RegisterPage extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    password: "",
    message: ""
  }

  componentDidMount() {
  }

  handleInputChange = (e) => {
    if (e.target.id === "email") {
      this.setState({
        email: e.target.value,
        message: "",
      })
    } else if (e.target.id === "password") {
      this.setState({
        password: e.target.value,
        message: "",
      })
    } else if (e.target.id === "phone") {
      this.setState({
        phone: e.target.value,
        message: "",
      })
    } else if (e.target.id === "name") {
      this.setState({
        name: e.target.value,
        message: "",
      })
    }
  }

  handleSignup = async (e) => {
    const email = this.state.email
    const password = this.state.password
    const phone = this.state.phone
    const name = this.state.name
    if (name === "") {
      this.setState({
        message: "Numele este gol."
      })
      return
    }
    if (email === "") {
      this.setState({
        message: "Emailul este gol."
      })
      return
    }
    if (phone === "") {
      this.setState({
        message: "Numarul de telefon este gol."
      })
      return
    }
    if (password === "") {
      this.setState({
        message: "Parola este goala."
      })
      return
    }

    this.setState({ loading: true });

    let res = await createUserWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        const uid = res.user.uid
        let user_data = {
          name: this.state.name,
          phone: this.state.phone,
          verified: false,
        }
        console.log('created account', uid, user_data);

        // refUsers.add(user_data).then((ref) => {
        //   this.setState({ loading: false });
        // }).catch((error) => {
        //   this.setState({ loading: false });

        //   console.log("Error setting user:", error);
        // })

        const resp = await addDoc(refUsers, user_data);

        let url = '/' + uid
        window.location = url
      }).catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        console.log(errorCode)
        console.log(errorMessage)
        this.setState({
          message: errorMessage,
          loading: false
        })
      })
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <title>{'Inregistrare Meniu.La'}</title>
        </Helmet>
        <div className="login-title">
          Sign Up
        </div>
        <div>
          <input className="login-input" type="text" placeholder="Nume" id="name" value={this.state.name} onChange={this.handleInputChange}></input>
        </div>
        <div>
          <input className="login-input" type="email" placeholder="Email" id="email" value={this.state.email} onChange={this.handleInputChange}></input>
        </div>
        <div>
          <input className="login-input" type="number" placeholder="Telefon" id="phone" value={this.state.phone} onChange={this.handleInputChange}></input>
        </div>
        <div>
          <input className="login-input" type="password" placeholder="Parola" id="password" value={this.state.password} onChange={this.handleInputChange}></input>
        </div>
        <div className="login-status">
          {this.state.message}
        </div>
        <div>
          {this.state.loading ?
            <Spinner animation="grow" variant="primary" />
            :
            <button className="login-button" onClick={this.handleSignup}>Inregistrare</button>
          }
        </div>
        <div>
          <span className="login-signup-desc">Ai deja un cont?</span>
          <a href="/login" className="login-signup-link">Intra in cont</a>
        </div>
      </div>
    )
  }
}

export default RegisterPage