import React from 'react';
import { Component } from 'react';
import Resizer from 'react-image-file-resizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTrash } from '@fortawesome/free-solid-svg-icons'

class EditProduct extends Component {
    state = {
        width: 0,
        height: 0,
        name: "",
        price: "",
        desc: "",
        files: [],
        images: []
    }

    componentDidMount() {
        const name = this.props.name
        const price = this.props.price
        const desc = this.props.desc
        const images = this.props.images

        this.setState({
            width: 0,
            height: 0,
            name: name,
            price: price,
            desc: desc,
            files: images === undefined ? [] : images,
        }, () => {
            // navigator.clipboard.readText().then(text => {
            //     if (name === undefined || name.length < 2) {
            //         // let json;
            //         // try {
            //         //     json = JSON.parse(text);

            //         //     this.setState({ 
            //         //         name: json.name, 
            //         //         price: parseFloat(json.price), 
            //         //         desc: json.desc 
            //         //     });
            //         // } catch (error) {

            //         // }      


            //         // this.setState({ 
            //         //     name: text
            //         // });
            //     }
            // });
        })

        window.addEventListener('resize', this.resizeListener)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener)
    }

    componentWillUpdate() {
        this.resizeListener()
    }

    resizeListener = (e) => {
        if (this.state.width !== window.innerWidth || this.state.height !== window.innerHeight) {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            e.stopPropagation();

            this.saveButton.click()
        }
    }

    handleInputChange = (e) => {
        if (e.target.id === "name") {
            this.setState({
                name: e.target.value
            })
        } else if (e.target.id === "price") {
            this.setState({
                price: e.target.value
            })
        } else if (e.target.id === "desc") {
            this.setState({
                desc: e.target.value
            })
        }
    }

    handleFileChange = (e) => {
        const file = e.target.files
        /*this.setState({               //use only this to upload original image without resize.
            files: e.target.files
        })*/

        for (let i = 0; i < file.length; i++) {
            Resizer.imageFileResizer(
                file[i], //is the file of the new image that can now be uploaded...
                512, // is the maxWidth of the  new image
                512, // is the maxHeight of the  new image
                'JPEG', // is the compressFormat of the  new image
                100, // is the quality of the  new image
                0, // is the rotatoion of the  new image
                uri => {
                    this.setState({
                        files: [...this.state.files, uri]
                    })
                    console.log(uri)
                },  // is the callBack function of the new image URI
                'blob' // is the output type of the new image
            )
        }
    }

    handleDeleteImage = (e, image) => {
        e.stopPropagation();

        let index = this.state.files.indexOf(image);
        if (index !== -1) {
            this.state.files.splice(index, 1);
            this.forceUpdate();
        }

    }

    render() {
        const cid = this.props.cid
        const pid = this.props.pid
        const cancelEditProduct = this.props.cancelEditProduct
        const handleAddProduct = this.props.handleAddProduct
        let imgWidth = this.state.width
        let imgHeight = this.state.height
        let imgClass
        const images = [...this.state.files.map((f, index) => index + 1)] //this.props.images

        let count = 4 - images.length;
        if (count > 0) {
            for (let i = 0; i < count; i++) {
                images.push('a');
            }
        } else {
            images.push('a');
        }

        let width = this.state.width;

        if (width > 600) {
            width = 600;
        }

        let image_list
        let margin = 60
        let itemMargin = 10;
        if (images.length === 1) {
            imgHeight = (imgWidth - margin - (itemMargin * images.length)) * 0.8
        } else if (images.length === 2) {
            imgWidth = (width - margin - (itemMargin * images.length)) / images.length
            imgHeight = imgWidth
        } else if (images.length === 3) {
            imgWidth = (width - margin - (itemMargin * images.length)) / images.length
            imgHeight = imgWidth
        } else if (images.length === 4) {
            imgWidth = (width - margin - (itemMargin * images.length)) / images.length
            imgHeight = imgWidth
        } else if (images.length === 5) {
            imgWidth = (width - margin - (itemMargin * images.length)) / images.length
            imgHeight = imgWidth
        } else if (images.length >= 5) {
            imgWidth = (width - margin - (itemMargin * images.length) - (50 * width) / 1000) / images.length
            imgHeight = imgWidth
        }

        imgClass = "item-image-holder-flex"
        image_list = images.map((value, index) => {
            let desc = ""
            let url = ""
            let item = this.state.files[index];

            if (this.state.files[index]) {
                if (typeof item === 'object') {
                    url = URL.createObjectURL(this.state.files[index])
                } else if (typeof item === 'string') {
                    url = item;
                }
            } else {
                desc = (<React.Fragment>
                    <FontAwesomeIcon icon={faImage} size={"2x"} opacity={0.3} swapOpacity={true} style={{ alignSelf: 'center' }} />
                    {/* <div className="item-image-holder-desc">
                                Adauga poza
                            </div> */}
                </React.Fragment>);
            }
            return <div
                key={value + "picture" + index}
                className="image-hollow-holder"
                style={{
                    backgroundImage: 'url(' + url + ')',
                    width: imgWidth,
                    height: imgHeight
                }} onClick={() => this.fileInput.click()}>
                <input type="file" accept="image/*" ref={fileInput => this.fileInput = fileInput} style={{ display: 'none' }} multiple onChange={this.handleFileChange} />
                {desc}
                {value !== 'a' &&
                    <div className="delete-icon-container" onClick={(e) => this.handleDeleteImage(e, item)}>
                        <FontAwesomeIcon icon={faTrash} size={"1x"} opacity={0.3} swapOpacity={true} style={{ alignSelf: 'center' }} />
                    </div>}
            </div>
        })
        return (
            <div>
                <div className="edit-item-top">
                    <input type="text" className="edit-item-name" placeholder="Product Name" id="name" value={this.state.name} onChange={this.handleInputChange} />
                    <input type="number" className="edit-item-price" placeholder="Price" id="price" value={this.state.price} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} />
                </div>
                <div className="edit-item-bottom">
                    <textarea type="text" className="edit-item-desc" placeholder="Product description" id="desc" value={this.state.desc} onChange={this.handleInputChange} />
                </div>
                <div className={imgClass}>
                    {image_list}
                </div>
                <div className="edit-item-button">
                    <div className="edit-item-button-left">
                        <button className="btn-cancel-product" onClick={cancelEditProduct}>Renunta</button>
                    </div>
                    <div className="edit-item-button-right">
                        <button ref={ref => this.saveButton = ref} className="btn-save-product" cid={cid} pid={pid} files={this.state.files} name={this.state.name} price={this.state.price} desc={this.state.desc} onClick={(e) => { handleAddProduct(e, this.state.files) }}>Salveaza</button>
                    </div>
                </div>
            </div>
        );
    };
};

export default EditProduct;