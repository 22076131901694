import React from 'react';
import { Component } from 'react';
import { auth } from '../config';
import { Helmet } from 'react-helmet';
import { onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';

class ForgotPasswordPage extends Component {
  state = {
    email: "",
    password: "king789123",
    message: ""
  }

  componentDidMount() {
    // auth.onAuthStateChanged(function(user) {
    //   if (user) {
    //     let url = '/' + user.uid
    //     window.location = url
    //   } else {
    //     console.log('empty user')
    //   }
    // })
    // Firebase v9+

    onAuthStateChanged(auth, (user) => {
      if (user) {
        let url = '/' + user.uid;
        window.location = url;
      }
    });
  }

  handleInputChange = (e) => {
    if (e.target.id === "email") {
      this.setState({
        email: e.target.value
      })
    }
  }

  handleSend = async (e) => {
    const email = this.state.email
    if (email === "") {
      this.setState({
        message: "Empty email."
      })
      return
    }
    console.log("send mail to " + email)

    // Firebase v9+

    let response = await sendPasswordResetEmail(auth, email);
    alert("Email sent to " + email + ". Please check your inbox and SPAM as well.")
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <title>{'Recuperare parola Meniu.La'}</title>
        </Helmet>
        <div className="login-title">
          Forgot Password
        </div>
        <div>
          <input className="login-input" type="email" placeholder="Your Email" id="email" value={this.state.email} onChange={this.handleInputChange}></input>
        </div>
        <div className="login-status">
          {this.state.message}
        </div>
        <div>
          <button className="login-button" onClick={this.handleSend}>Send email</button>
        </div>
        <div>
          <span className="login-signup-desc">Already have an account?</span>
          <a href="/login" className="login-signup-link">Sign In</a>
        </div>
        <div>
          <span className="login-signup-desc">Don't have an account?</span>
          <a href="/inregistrare" className="login-signup-link">Sign Up</a>
        </div>
      </div>
    )
  }
};

export default ForgotPasswordPage;