import React from 'react';
import { Component } from 'react';
import { auth, refUsers, refLocations } from '../config';
import { Helmet } from 'react-helmet';
import Spinner from 'react-bootstrap/Spinner';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      message: ""
    }

    window.location = 'https://www.facebook.com/MeniuriDigitale';
  }

  render() {
    return (
      <div className="App">
        
      </div>
    )
  }
}

export default HomePage