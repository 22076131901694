import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import from react-router-dom
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import './App.css';
import './assets/fonts/montserrat/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LocationPageWithParams } from './pages/LocationPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/inregistrare" element={<RegisterPage />} />
        <Route path="/parola" element={<ForgotPasswordPage />} />
        <Route path="/:slug/:action" element={<LocationPageWithParams />} />
        <Route path="/:slug" element={<LocationPageWithParams />} />
      </Routes>
    </Router>
  );
}

export default App;
