import React, { useState, useEffect } from 'react';
import { auth, refUsers, refLocations, db } from '../config';
import { Helmet } from 'react-helmet';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDocs, query, where } from 'firebase/firestore';


const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        console.log(uid, 'user');
        try {
          const userDoc = await doc(db, 'users', uid);
          console.log(userDoc, 'userDoc');
          // if exists
          if (userDoc) {
            // const locationSnapshot = await refLocations.where("uid", "==", uid).get();
            const locationSnapshot = await getDocs(query(refLocations, where("uid", "==", uid)));
            let url = "/";

            locationSnapshot.docs.forEach((doc) => {
              url = '/' + doc.data().slug;
            });

            if (url !== '/') {
              navigate(url);
            } else if (uid) {
              navigate(url + uid);
            }
          } else {
            console.log("no user data for : " + uid);
            if (uid) {
              navigate("/" + uid);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.log('empty user');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [refUsers]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      setEmail(value);
      setMessage("");
    } else if (id === "password") {
      setPassword(value);
      setMessage("");
    }
  };

  const handleLogin = async () => {
    if (!email) {
      setMessage("Emailul este gol.");
      return;
    }
    if (!password) {
      setMessage("Parola este goala.");
      return;
    }

    setLoading(true);

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const uid = res.user.uid;
      // console.log(uid);
      // handle post-login logic here if needed
    } catch (error) {
      console.error("Login error:", error);
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <Helmet>
        <title>Login Meniu.La</title>
      </Helmet>
      <div className="login-title">
        Intra in cont
      </div>
      <div>
        <input
          className="login-input"
          type="email"
          placeholder="Email"
          id="email"
          value={email}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <input
          className="login-input"
          type="password"
          placeholder="Parola"
          id="password"
          value={password}
          onChange={handleInputChange}
        />
      </div>
      <div className="login-status">
        {message}
      </div>
      <div>
        {loading ? (
          <Spinner animation="grow" variant="primary" />
        ) : (
          <button className="login-button" onClick={handleLogin}>Login</button>
        )}
      </div>
      <div className="login-forgot-desc">
        <a href="/parola">Ai uitat parola?</a>
      </div>
      <div>
        <span className="login-signup-desc">Nu ai cont?</span>
        <a href="/inregistrare" className="login-signup-link">Inregistreaza-te</a>
      </div>
    </div>
  );
};

export default LoginPage;
