import React from 'react';
import { Component } from 'react';
import Category from './Category';

class Menu extends Component {
    render() {
        let buttonClass = ""
        let categoriesDiv = []
        let buttonDiv = ""
        const categories = this.props.categories
        const products = this.props.products
        const pictures = this.props.pictures
        const name = this.props.name
        const editmode = this.props.editmode
        const verified = this.props.verified
        const search = this.props.search;
        const slug = this.props.slug;
        const handleAddProduct = this.props.handleAddProduct
        const handleMoveProduct = this.props.handleMoveProduct
        const handleMoveCategory = this.props.handleMoveCategory
        const handleDeleteProduct = this.props.handleDeleteProduct
        const handleActivateProduct = this.props.handleActivateProduct
        const handleActivateCategory = this.props.handleActivateCategory
        const handleEditCategory = this.props.handleEditCategory

        if (categories === undefined || categories === null) {
            categoriesDiv = ""
        } else {
            categories.forEach(function (category, index) {
                let filtered_products = products.filter(item => item.category_id === category.cid);

                if (category.menu == name && (filtered_products.length > 0 || search === '')) {
                    categoriesDiv.push(<Category key={name + "category" + index}
                        cid={category.cid}
                        name={category.name}
                        editmode={editmode}
                        verified={verified}
                        activated={category.activated}
                        products={products}
                        categories={categories}
                        category={category}
                        pictures={pictures}
                        slug={slug}
                        handleAddProduct={handleAddProduct}
                        handleMoveProduct={handleMoveProduct}
                        handleMoveCategory={handleMoveCategory}
                        handleActivateProduct={handleActivateProduct}
                        handleDeleteProduct={handleDeleteProduct}
                        handleActivateCategory={handleActivateCategory}
                        handleEditCategory={handleEditCategory}
                    />)
                }
            })
        }
        if (verified === false) {
            buttonClass = "btn-add-category disabled"
        } else {
            buttonClass = "btn-add-category"
        }
        if (editmode === false) {
            buttonDiv = ""
        } else {
            buttonDiv =
                <div>
                    <button className={buttonClass} menu={name} onClick={this.props.handleAddCategory}>Adauga categorie</button>
                </div>
        }
        return (
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <div id={'menu-' + name} className="menu-name">
                    {name === 'Bauturi' ? 'Bautura' : name}
                </div>
                {categoriesDiv}
                {buttonDiv}
            </div>
        );
    };
};

export default Menu;