

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence, collection } from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAXMeMNx-rmzlSY1gLPEwijLEQrrDSOFqo",
  authDomain: "meniula-2ea76.firebaseapp.com",
  databaseURL: "https://meniula-2ea76.firebaseio.com",
  projectId: "meniula-2ea76",
  storageBucket: "meniula-2ea76.appspot.com",
  messagingSenderId: "1032141622304",
  appId: "1:1032141622304:web:f9dca682d81470ff894045",
  measurementId: "G-HSRQDZQ69M"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Enable Firestore persistence
enableIndexedDbPersistence(db).catch((err) => {
  console.error("Firebase persistence error: ", err);
});

// Initialize analytics if available
let analytics;
if (typeof window !== 'undefined' && 'measurementId' in firebaseConfig) {
  analytics = getAnalytics(app);
}

// Create references to Firestore collections
export const refUsers = collection(db, "users");
export const refLocations = collection(db, "locations");
export const refCategory = collection(db, "category");
export const refProducts = collection(db, "products");
export const refPictures = collection(db, "pictures");

export { auth, db, storage };
export default firebaseConfig;