import React from 'react';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
//import Tooltip from "react-power-tooltip";
import Slider from "react-slick";
import Tooltip from 'react-power-tooltip'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { endpoint } from '../pages/LocationPage';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            showEditTooltip: false
        }

        this.resizeListener();
    }

    shouldComponentUpdate(props, state) {
        if ((props !== this.props
            || state.height !== this.state.height
            || state.width !== this.state.width
            || state.showEditTooltip !== this.state.showEditTooltip)
        ) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeListener)

        document.body.addEventListener('click', this.bodyClick);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener)
        document.body.removeEventListener('click', this.bodyClick);
    }

    componentWillUpdate() {
        this.resizeListener()
    }

    bodyClick = (e) => {
        if (e.target.id !== 'tooltip' && e.target.className !== 'tooltip' && this.state.showEditTooltip !== false) {
            this.setState({ showEditTooltip: false });
        }
    }

    resizeListener = (e) => {
        if (this.state.width !== window.innerWidth || this.state.height !== window.innerHeight) {

            let width = window.innerWidth;
            let height = window.innerHeight;

            if (window.innerWidth > 600) {
                width = 600;
            }

            if (window.innerWidth > window.innerHeight) {
                height = width;
            }
            this.setState({
                width,
                height
            })
        }
    }

    handleEditClick = (bool) => {
        this.setState({ showEditTooltip: !this.state.showEditTooltip });

        return false;
    }

    handleEditProduct = (pid) => {
        console.log('edit product', pid);

        // const pid = e.target.getAttribute('pid')
        const convertEditProduct = this.props.convertEditProduct
        convertEditProduct(pid)
        this.handleEditClick()
    }

    handleActivateProduct = (pid) => {
        console.log('activate product', pid);

        // const pid = e.target.getAttribute('pid')
        const convertActivateProduct = this.props.convertActivateProduct;
        convertActivateProduct(pid);
    }

    handleMoveProduct = (pid, cid) => {
        // const pid = e.target.getAttribute('pid')
        let pos = prompt(`Introdu noua pozitie a produsului (1 - ${this.props.products.length})`)

        if (isNaN(pos) || parseInt(pos) > this.props.products.length) {
            pos = prompt(`Alege o pozitie de la 1 la ${this.props.products.length}`)
        }

        if (!isNaN(pos) && parseInt(pos) <= this.props.products.length) {
            this.props.handleMoveProduct(pid, pos, cid);
            this.handleEditClick()
        }
    }

    handleDeleteProduct = (pid) => {
        if (window.confirm('Esti sigur ca vrei sa stergi produsul?')) {
            this.props.handleDeleteProduct(pid)
            this.handleEditClick()
        }
    }


    render() {
        const news_slider_setting = {
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            variableWidth: false,
        }

        const pid = this.props.pid
        const cid = this.props.cid
        const name = this.props.name !== undefined ? this.props.name.trim() : this.props.name
        const price = this.props.price
        const desc = this.props.desc === null || this.props.desc === undefined ? this.props.desc : <span dangerouslySetInnerHTML={{ __html: this.props.desc.replace(/\[.*?[^\]]\]/g, function (match) { return "<span class=\"alergen\">" + match.substr(1, match.length - 2) + "</span>"; }) }}></span>;
        const editmode = this.props.editmode
        const images = this.props.images
        const activated = this.props.activated;
        let imgWidth = this.state.width
        let imgHeight = this.state.height
        let imgClass
        let image_list
        let margin = 68
        let itemOp = []

        if (images.length === 1) {
            imgHeight = (imgWidth - margin) * 0.8
        } else if (images.length === 2) {
            imgWidth = (this.state.width - margin) / 2
            imgHeight = imgWidth
        } else if (images.length === 3) {
            imgWidth = (this.state.width - margin) / 3
            imgHeight = imgWidth
        } else if (images.length === 4) {
            imgWidth = (this.state.width - margin) / 4
            imgHeight = imgWidth
        } else if (images.length >= 5) {
            imgWidth = (this.state.width - margin - (50 * this.state.width) / 1000) / 4
            imgHeight = imgWidth
        }

        if (imgHeight >= imgWidth) {
            imgHeight = imgWidth;
        }
        if (images.length < 2) {
            imgClass = "item-image-flex"
            // image_list = images.map((value, index) => {
            //     return <div
            //         key={pid + "picture" + index}
            //         className="image-holder"
            //         style={{
            //             backgroundImage: 'url(' + value + ')',
            //             width: imgWidth,
            //             height: imgHeight
            //         }}>
            //     </div>
            // })
            // Convert above to <img
            image_list = images.map((value, index) => {
                return <img
                    key={pid + "picture" + index}
                    alt={name}
                    className="image-holder"
                    style={{ width: '100%', objectFit: 'cover' }}
                    loading="lazy"
                    src={`${endpoint}/api/db/image?imageUrl=${encodeURIComponent(value)}`}
                >
                </img>
            })
        } else {
            // imgClass = "item-image"
            // image_list = <Slider {...news_slider_setting}>
            //     {images.map((value, index) => {
            //         return <div key={pid + "picture" + index}><div className="image-holder"
            //             style={{
            //                 backgroundImage: 'url(' + value + ')',
            //                 width: imgWidth,
            //                 height: imgHeight
            //             }}>
            //         </div></div>
            //     })}
            // </Slider>

            // Horizontal scroller

            image_list = <div className="image-holder" style={
                {
                    display: 'flex',
                    overflowX: 'scroll',
                }
            }>
                {images.map((value, index) => {
                    console.log(`${endpoint}/api/db/image?imageUrl=${encodeURIComponent(value)}`);

                    return <img
                        key={pid + "picture" + index}
                        alt={name}
                        className="image-holder"
                        style={{ width: '75%', objectFit: 'cover' }}
                        loading="lazy"
                        src={`${endpoint}/api/db/image?imageUrl=${encodeURIComponent(value)}`}
                    >
                    </img>
                })}
            </div>
        }

        if (editmode === false) {
            itemOp = ""
        } else {
            itemOp =
                <React.Fragment>
                    <div
                        id={'tooltip'}
                        className="item-operation"
                        pid={pid}
                        onClick={(e) => this.handleEditClick(e)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                        <div className='popup-edit-container'>
                            {this.state.showEditTooltip && <Tooltip
                                animation={'bounce'}
                                show={true}
                                textBoxWidth={'auto'}
                                color={'black'}
                            >
                                <span onPointerUpCapture={() => this.handleEditProduct(pid)}>Modifica</span>
                                <span onPointerUpCapture={() => this.handleActivateProduct(pid)}>{activated === undefined || activated === null || activated ? 'Dezactiveaza' : 'Activeaza'}</span>
                                <span onPointerUpCapture={() => this.handleMoveProduct(pid, cid)}>Schimba pozitia</span>
                                <span onPointerUpCapture={() => this.handleDeleteProduct(pid)}>Sterge</span>
                            </Tooltip>}
                        </div>
                    </div>
                    {/* <div className="item-operation" pid={pid} onClick={this.handleMoveProduct}>
                    <FontAwesomeIcon icon={faArrowsAlt}/>
                </div>
                <div className="item-operation" pid={pid} onClick={this.handleDeleteProduct}>
                    <FontAwesomeIcon icon={faTrash}/>
                </div> */}

                </React.Fragment>
        }

        if (activated === false && !editmode) {
            return null;
        }

        return (
            <div
                className="item-body"
            >
                <div className="item-name">
                    <span className="item-edit">
                        {itemOp}
                        <div style={activated === false && editmode ? { opacity: 0.4 } : null}>
                            {name}
                            {activated === false && editmode &&
                                <div className="deactivated">Dezactivat</div>}
                        </div>
                    </span>
                    {price !== null && price !== "" &&
                        <span className="item-price" style={activated === false && editmode ? { opacity: 0.4 } : null}>
                            {price + " LEI"}
                        </span>}
                </div>
                <div className="item-desc" style={activated === false && editmode ? { opacity: 0.4 } : null}>
                    {desc}
                </div>
                <div className={imgClass}>
                    {image_list}
                </div>
            </div>
        );
    };
};

export default Product;