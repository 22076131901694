import React from 'react';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Product from './Product';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'react-power-tooltip';
import EditProduct from './EditProduct';

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            extend: false,
            edit: false,
            epid: "",
        }

        if (this.props.slug === "cununie") {
            this.state.extend = true;
        }
    }

    shouldComponentUpdate(props, state) {
        if (state !== this.state || props !== this.props) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        if (this.state.epid !== "" || this.state.edit) {
            this.setState({
                edit: false,
                epid: "",
            })
        }

        document.body.addEventListener('click', this.bodyClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClick);
    }

    componentWillReceiveProps() {
        if (this.state.epid !== "" || this.state.edit) {
            this.setState({
                edit: false,
                epid: "",
            })
        }
    }

    handleExtension = (e) => {
        if (this.props.slug === "cununie") {
            return;
        }

        if (this.state.extend === true) {
            this.setState({
                extend: false
            })
        } else {
            this.setState({
                extend: true
            })
        }
    }

    showEmptyEditProduct = (e) => {
        if (this.state.epid === "") {
            this.setState({
                edit: true
            })
        }
    }

    cancelEditProduct = (e) => {
        this.setState({
            edit: false,
            epid: "",
        })
    }

    bodyClick = (e) => {
        if (e.target.id !== 'tooltip' && e.target.className !== 'tooltip' && this.state.showEditTooltip !== false) {
            this.setState({ showEditTooltip: false });
        }
    }

    convertEditProduct = (pid) => {
        console.log('convertEditProduct : ' + pid)
        this.setState({
            epid: pid
        })
    }

    handlePopupClick = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({ showEditTooltip: !this.state.showEditTooltip });

        return false;
    }

    handleMoveCategory = (cid) => {
        let cats = this.props.categories.filter(item => item.menu === this.props.category.menu);

        // const pid = e.target.getAttribute('pid')
        let pos = prompt(`Introdu noua pozitie a categories (1 - ${cats.length})`)

        if (isNaN(pos) || parseInt(pos) > cats.length) {
            pos = prompt(`Alege o pozitie de la 1 la ${cats.length}`)
        }

        if (!isNaN(pos) && parseInt(pos) <= cats.length) {
            this.props.handleMoveCategory(pos, cid, this.props.category.menu);
            this.handlePopupClick()
        }
    }

    render() {
        let productListDiv = []
        let buttonClass = ""
        let buttonDiv = []
        let productEditDiv = []
        let itemOp = [];
        const name = this.props.name
        const cid = this.props.cid
        const products = this.props.products
        const pictures = this.props.pictures
        const editmode = this.props.editmode
        const verified = this.props.verified
        const activated = this.props.activated
        const handleAddProduct = this.props.handleAddProduct
        const handleActivateCategory = this.props.handleActivateCategory
        const handleEditCategory = this.props.handleEditCategory

        if (products === undefined || products === null) {
            productListDiv = ""
        } else {
            let filtered_products = products.filter(item => item.category_id === cid);

            const sorted_products = filtered_products.sort((a, b) => {
                return parseFloat(a.position) - parseFloat(b.position)
            })

            sorted_products.forEach((product, index) => {
                if (product.category_id === cid) {
                    let images = []
                    if (pictures === undefined || pictures === null) {
                        images = []
                    } else {
                        pictures.forEach(function (picture, index) {
                            if (picture.product_id === product.pid) {
                                images.push(picture.url)
                            }
                        })
                    }
                    if (product.pid === this.state.epid) {
                        productListDiv.push(
                            <EditProduct
                                key={cid + "productEdit" + index}
                                cid={cid}
                                pid={product.pid}
                                name={product.name}
                                price={product.price}
                                desc={product.desc}
                                images={images}
                                handleAddProduct={handleAddProduct}
                                cancelEditProduct={this.cancelEditProduct}
                            />
                        )
                    } else {
                        productListDiv.push(
                            <Product
                                key={cid + "product" + index}
                                pid={product.pid}
                                name={product.name}
                                price={product.price}
                                desc={product.desc}
                                position={product.position}
                                images={images}
                                products={filtered_products}
                                cid={product.category_id}
                                activated={product.activated}
                                editmode={editmode}
                                convertEditProduct={this.convertEditProduct}
                                convertActivateProduct={this.props.handleActivateProduct}
                                handleMoveProduct={this.props.handleMoveProduct}
                                handleDeleteProduct={this.props.handleDeleteProduct}
                            />
                        )
                    }
                }
            })
        }

        if (editmode === false) {
            itemOp = ""
        } else {
            itemOp =
                <span
                    id={'tooltip'}
                    className="item-operation"
                    cid={cid}
                    onClick={(e) => this.handlePopupClick(e)}
                >
                    <FontAwesomeIcon icon={faEdit} />
                    <div className='popup-edit-container'>
                        {this.state.showEditTooltip && <Tooltip
                            animation={'bounce'}
                            show={true}
                            textBoxWidth={'auto'}
                            color={'black'}
                        >
                            <span onPointerUpCapture={() => handleActivateCategory(cid)}>{activated === undefined || activated === null || activated ? 'Dezactiveaza' : 'Activeaza'}</span>
                            <span
                                onPointerUpCapture={() => {
                                    let newName = prompt("Introdu numele categories", name);
                                    handleEditCategory(cid, newName);
                                }}
                            >Schimba numele</span>
                            <span onPointerUpCapture={() => this.handleMoveCategory(cid)}>Schimba pozitia</span>
                        </Tooltip>}
                    </div>
                </span>
        }

        let extendClass = ""
        let visibleClass = ""
        if (this.state.extend === false) {
            extendClass = "category-slot"
            visibleClass = "category-itemlist invisible"
        } else {
            extendClass = "category-slot slot-extended"
            visibleClass = "category-itemlist visible"
        }
        if (verified === false) {
            buttonClass = "btn-add-category disabled"
        } else {
            buttonClass = "btn-add-category"
        }
        if (editmode === false) {
            buttonDiv = ""
        } else {
            buttonDiv =
                <div>
                    <button className={buttonClass} cid={cid} onClick={this.showEmptyEditProduct}>Adauga produs</button>
                </div>
        }
        if (this.state.edit === true) {
            productEditDiv =
                <EditProduct cid={cid} handleAddProduct={handleAddProduct} cancelEditProduct={this.cancelEditProduct} />
        }

        if (activated === false && !editmode) {
            return null;
        }

        return (
            <div>
                <div className={"category-header " + (this.state.extend && "category-extended")} onClick={this.handleExtension}>
                    {itemOp}
                    <span className="category-name" style={activated === false && editmode ? { opacity: 0.4 } : null}>
                        {name}
                    </span>
                    <span className={extendClass} style={activated === false && editmode ? { opacity: 0.4 } : null}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                </div>
                {this.state.extend &&
                    <div className={visibleClass}>
                        {productListDiv}
                        {productEditDiv}
                        {buttonDiv}
                    </div>
                }
            </div>
        );
    };
};

export default Category;